import React from "react"
import { Link } from 'gatsby'
import SEO from "../components/seo"
import Header from "../components/header"


const NotFoundPage = () => (
  <>
    <SEO title="404: Page Not found" />
    <Header></Header> 
    <main className="o-section">
        <div className="u-grid u-centered o-text">
          <h1>Page not found (404)</h1>
          <p>There has been a glitch in the matrix. Try returning to the <Link to="/">homepage</Link>.</p>
        </div>
    </main>
  </>

)

export default NotFoundPage
